import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { share, timer, tap, map, ReplaySubject, pipe, Observable } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { AuthenticatorService } from './authenticator.service';
import { Response } from '../models/response.interface';
import { environment } from 'src/environments/environment';
import { Country } from '../models/entities/country.interface';
import { City } from '../models/entities/city.interface';
import { Currency } from '../models/entities/currency.interface';
import { StudyAbroadRepresentative } from '../models/entities/study-abroad-representative.interface';
import { StudyAbroadPoint } from '../models/entities/study-abroad-point.interface';
import { ServiceProvider } from '../models/entities/service-provider.interface';
import { School } from '../models/entities/school.interface';
import { CorporateInquiry } from '../models/entities/corporate-inquiry.interface';
import { WebPortal } from '../models/entities/web-portal.interface';
import { Branch } from '../models/entities/branch.interface';
import { User } from '../models/user.interface';

export const CACHE_TIMEOUT = 1000 * 60 * 60;

@Injectable({
	providedIn: 'root'
})
export class LookupService {
	constructor(private authenticatorService: AuthenticatorService) {}

	getLookupObservable<T>(url: string, cacheTimeout: number = CACHE_TIMEOUT): Observable<T[]> {
		return ajax<Response<T[]>>({
			url: `${environment.API_URL}api/${url}`,
			method: 'GET',
			headers: {
				Authorization: `Bearer ${this.authenticatorService.tokenSubject.value}`
			}
		}).pipe(
			tap(() => console.log('request happened')),
			map((response) => {
				return response.response.data;
			}),
			share({
				connector: () => new ReplaySubject(1),
				resetOnComplete: () => timer(CACHE_TIMEOUT)
			})
		);
	}

	private _countries$!: Observable<Country[]>;
	get countries$() {
		if (!this._countries$) {
			this._countries$ = this.getLookupObservable<Country>('partner/location-service/country');
		}
		return this._countries$;
	}

	private _cities!: Observable<City[]>;
	get cities$() {
		if (!this._cities) {
			this._cities = this.getLookupObservable<City>('partner/location-service/city');
		}
		return this._cities;
	}

	private _turkeyCities!: Observable<City[]>;
	get turkeyCities$() {
		if (!this._turkeyCities) {
			this._turkeyCities = this.getLookupObservable<City>('partner/location-service/city?CountryCode=TUR');
		}
		return this._turkeyCities;
	}

	private _currencies!: Observable<Currency[]>;
	get currencies$() {
		if (!this._currencies) {
			this._currencies = this.getLookupObservable<Currency>('partner/accounting-service/currency', CACHE_TIMEOUT);
		}
		return this._currencies;
	}

	private _studyAbroadRepresentatives!: Observable<StudyAbroadRepresentative[]>;
	get studyAbroadRepresentatives$() {
		if (!this._studyAbroadRepresentatives) {
			this._studyAbroadRepresentatives = this.getLookupObservable<StudyAbroadRepresentative>(
				'partner/accounting-service/studyabroad/representative'
			);
		}
		return this._studyAbroadRepresentatives;
	}

	private _studyAbroadPoints!: Observable<StudyAbroadPoint[]>;
	get studyAbroadPoints$() {
		if (!this._studyAbroadPoints) {
			this._studyAbroadPoints = this.getLookupObservable<StudyAbroadPoint>('partner/accounting-service/studyabroad/point');
		}
		return this._studyAbroadPoints;
	}

	private _serviceProviders!: Observable<ServiceProvider[]>;
	get serviceProviders$() {
		if (!this._serviceProviders) {
			this._serviceProviders = this.getLookupObservable<ServiceProvider>('partner/accounting-service/serviceprovider');
		}
		return this._serviceProviders;
	}

	private _schools!: Observable<School[]>;
	get schools$() {
		if (!this._schools) {
			this._schools = this.getLookupObservable<School>('partner/student-service/school');
		}
		return this._schools;
	}

	private _corporateInquiries!: Observable<CorporateInquiry[]>;
	get corporateInquiries$() {
		if (!this._corporateInquiries) {
			this._corporateInquiries = this.getLookupObservable<CorporateInquiry>('partner/student-service/corporate-inquiry');
		}
		return this._corporateInquiries;
	}

	private _webPortals!: Observable<WebPortal[]>;
	get webPortals$() {
		if (!this._webPortals) {
			this._webPortals = this.getLookupObservable<WebPortal>('partner/location-service/web-portal');
		}
		return this._webPortals;
	}

	private _branches!: Observable<Branch[]>;
	get branches$() {
		if (!this._branches) {
			this._branches = this.getLookupObservable<Branch>('partner/location-service/branch');
		}
		return this._branches;
	}

	private _allUsers!: Observable<User[]>;
	get allUsers$() {
		if (!this._allUsers) {
			this._allUsers = this.getLookupObservable<User>('partner/auth-service/user');
		}
		return this._allUsers;
	}
}
