import { ChangeDetectorRef, Component } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';

import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';

import { VexBreadcrumbsComponent } from '@vex/components/vex-breadcrumbs/vex-breadcrumbs.component';
import { VexPageLayoutContentDirective } from '@vex/components/vex-page-layout/vex-page-layout-content.directive';
import { VexPageLayoutHeaderDirective } from '@vex/components/vex-page-layout/vex-page-layout-header.directive';
import { VexPageLayoutComponent } from '@vex/components/vex-page-layout/vex-page-layout.component';
import { VexSecondaryToolbarComponent } from '@vex/components/vex-secondary-toolbar/vex-secondary-toolbar.component';

import { fadeInUp400ms } from '@vex/animations/fade-in-up.animation';
import { stagger40ms } from '@vex/animations/stagger.animation';

import { ApiService } from 'src/app/core/services/api.service';
import { TableColumn } from '@vex/interfaces/table-column.interface';
import { Response } from 'src/app/core/models/response.interface';
import { AsyncPipe, DatePipe } from '@angular/common';
import { AuthenticatorService } from 'src/app/core/services/authenticator.service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatMenuModule } from '@angular/material/menu';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { Enums } from 'src/app/core/models/enums/enum';
import { debounceTime } from 'rxjs';
import { DashboardChartComponent } from './dashboard-chart/dashboard-chart.component';
import { ApexNonAxisChartSeries } from 'ng-apexcharts';
import { DashboardPremiumTableComponent } from './dashboard-premium-table/dashboard-premium-table.component';
import { PipesModule } from 'src/app/core/pipes/pipes.module';
import { PremiumPreference } from 'src/app/core/models/entities/premium-preference';
import { EntityService } from 'src/app/core/services/entity.service';
import { DashboardPartnerTitleComponent } from './dashboard-partner-title.component';

export interface PartnerDashboardDto {
	partnerId: number;
	partnerType: number;
	inquiry: number;
	inquiryActive: number;
	inquiryCancel: number;
	enrollment: number;
	enrollmentApplication: number;
	enrollmentTravel: number;
	enrollmentProgram: number;
	enrollmentCancel: number;
	enrollmentCompleted: number;
	enrollmentDeserved: number;
	partnerPackageType?: number;
	students: PartnerDashboardStudentDto[];
	premiums: PartnerDashboardPremiumItemDto[];
	preferences: Preferences;
}

export interface PartnerDashboardPremiumItemDto {
	currencyId: number;
	payedAmount: number;
	totalAmount: number;
	remainingAmount: number;
}

export interface PartnerDashboardStudentDto {
	id: number;
	student: StudentDto;
	status: number;
	programType: number;
	branchId: number;
	branchName: string;
	consultantId: number;
	consultantName: string;
	schoolId: number | null;
	schoolName: string;
	schoolBranchId: number | null;
	schoolBranchName: string;
	programId: number | null;
	programName: string;
	programCountryId: number | null;
	programCountryName: string;
	programCityId: number | null;
	programCityName: string;
	inquiryDate: string | null;
	enrollmentDate: string | null;
	programStartDate: string | null;
	premiumAmount: number;
	premiumCurrencyId: number;
	paid: boolean;
	studentSource: number;
	referrerId: number | null;
	referrerText: string;
}

export interface StudentDto {
	id: number;
	firstName: string;
	lastName: string;
	gender: number;
	email: string;
	phoneNumber: string;
	cityId: number | null;
	cityName: string;
	photo: string;
	identityNumber: string;
	birthDate: string | null;
	educationLevel: number;
	lastSchool: string;
	address: string;
}

export interface Preferences {
	individual: PremiumPreference;
	silver: PremiumPreference;
	gold: PremiumPreference;
	platinum: PremiumPreference;
}

@Component({
	selector: 'vex-dashboard',
	standalone: true,
	animations: [fadeInUp400ms, stagger40ms],
	imports: [
		FormsModule,
		ReactiveFormsModule,
		TranslateModule,
		RouterModule,

		MatFormFieldModule,
		MatInputModule,
		MatButtonModule,
		MatIconModule,
		MatTableModule,
		MatPaginatorModule,
		MatTooltipModule,
		MatProgressBarModule,
		MatProgressSpinnerModule,
		MatSelectModule,
		MatFormFieldModule,
		MatMenuModule,
		MatCheckboxModule,

		VexSecondaryToolbarComponent,
		VexBreadcrumbsComponent,
		VexPageLayoutComponent,
		VexPageLayoutHeaderDirective,
		VexPageLayoutContentDirective,

		PipesModule,
		AsyncPipe,
		DatePipe,
		TranslateModule,

		DashboardChartComponent,
		DashboardPremiumTableComponent,
		DashboardPartnerTitleComponent
	],
	templateUrl: './dashboard.component.html',
	styleUrl: './dashboard.component.scss'
})
export class DashboardComponent {
	constructor(
		private apiService: ApiService,
		private authenticatorService: AuthenticatorService,
		private matSnackBar: MatSnackBar,
		private cdr: ChangeDetectorRef,
		private translateService: TranslateService,
		private entityService: EntityService
	) {}

	loading = false;
	dataSource: MatTableDataSource<PartnerDashboardStudentDto> = new MatTableDataSource<PartnerDashboardStudentDto>();
	result?: PartnerDashboardDto;

	idColumn: TableColumn = { label: 'ID', property: 'id', visible: false };
	studentColumn: TableColumn = { label: 'STUDENT', property: 'student', visible: true };
	contactColumn: TableColumn = { label: 'CONTACT', property: 'contact', visible: false };

	programTypeColumn: TableColumn = { label: 'PROGRAM_TYPE', property: 'programType', visible: true };
	studentSourceColumn: TableColumn = { label: 'STUDENT_SOURCE', property: 'studentSource', visible: false };
	enrollmentDateColumn: TableColumn = { label: 'ENROLLMENT_DATE', property: 'enrollmentDate', visible: true };
	programStartDateColumn: TableColumn = { label: 'PROGRAM_START_DATE', property: 'programStartDate', visible: false };

	statusColumn: TableColumn = { label: 'STATUS', property: 'custom', visible: true };
	branchColumn: TableColumn = { label: 'BRANCH', property: 'branch', visible: false };

	programCountryColumn: TableColumn = { label: 'PROGRAM_COUNTRY', property: 'programCountry', visible: true };
	schoolColumn: TableColumn = { label: 'SCHOOL', property: 'school', visible: true };
	consultantColumn: TableColumn = { label: 'CONSULTANT', property: 'consultant', visible: false };
	premiumColumn: TableColumn = { label: 'PREMIUM', property: 'premium', visible: true };
	columns: TableColumn[] = [
		this.idColumn,
		this.studentColumn,
		this.contactColumn,

		this.programTypeColumn,
		this.studentSourceColumn,
		this.enrollmentDateColumn,
		this.programStartDateColumn,
		this.statusColumn,
		this.programCountryColumn,

		this.branchColumn,
		this.consultantColumn,

		this.schoolColumn,
		this.premiumColumn
	];
	showPricingTable = false;
	deservedPercentage: number = 0;

	searchCtrl = new FormControl('');
	programTypeCtrl = new FormControl(null);
	statusCtrl = new FormControl(null);
	yearCtrl = new FormControl(new Date().getFullYear());

	programTypes: { value: number; label: string }[] = [];
	years: number[] = [];

	series?: ApexNonAxisChartSeries;
	labels?: string[];
	partnerTitle?: string;

	ngAfterContentInit(): void {
		this.loadData();

		var programType: any = Enums.ProgramType;
		for (let key in programType.values) {
			this.programTypes.push({
				value: programType.values[key],
				label: `ENUM.${programType.key}.${programType.values[key]}`
			});
		}

		// from 2016 to current year
		for (let index = 2016; index <= new Date().getFullYear(); index++) {
			this.years.push(index);
		}

		this.searchCtrl.valueChanges.pipe(debounceTime(300)).subscribe(() => {
			this.loadData();
		});
		this.programTypeCtrl.valueChanges.subscribe(() => {
			this.loadData();
		});
		this.yearCtrl.valueChanges.subscribe(() => {
			this.loadData();
		});
		this.statusCtrl.valueChanges.subscribe(() => {
			this.loadData();
		});
	}

	loadData() {
		this.loading = true;
		this.cdr.detectChanges();

		var model: any = {
			year: this.yearCtrl.value
		};
		if (this.programTypeCtrl.value) {
			model.programType = this.programTypeCtrl.value;
		}
		if (this.searchCtrl.value) {
			model.search = this.searchCtrl.value;
		}
		if (this.statusCtrl.value) {
			model.status = this.statusCtrl.value;
		}
		this.apiService.post<Response<PartnerDashboardDto>>('partner/student-service/dashboard/partner', model).subscribe({
			next: async (response) => {
				this.result = response.data;
				this.deservedPercentage = (response.data.enrollmentDeserved * 100) / 40;
				if (this.deservedPercentage > 100) {
					this.deservedPercentage = 100;
				}
				this.series = [
					response.data.inquiryActive,
					response.data.inquiryCancel,
					response.data.enrollmentApplication,
					response.data.enrollmentTravel,
					response.data.enrollmentProgram,
					response.data.enrollmentCancel,
					response.data.enrollmentCompleted
				];
				this.labels = [
					this.translateService.instant('PAGE.DASHBOARD.INQUIRY_ACTIVE'),
					this.translateService.instant('PAGE.DASHBOARD.INQUIRY_CANCEL'),
					this.translateService.instant('PAGE.DASHBOARD.ENROLLMENT_APPLICATION'),
					this.translateService.instant('PAGE.DASHBOARD.ENROLLMENT_TRAVEL'),
					this.translateService.instant('PAGE.DASHBOARD.ENROLLMENT_PROGRAM'),
					this.translateService.instant('PAGE.DASHBOARD.ENROLLMENT_CANCEL'),
					this.translateService.instant('PAGE.DASHBOARD.ENROLLMENT_COMPLETED')
				];

				this.dataSource = new MatTableDataSource<PartnerDashboardStudentDto>(response.data.students);
				this.loading = false;
				this.cdr.detectChanges();
				
			},
			error: (error) => {
				this.matSnackBar.open('Error fetching students', 'Close', {
					duration: 3000
				});
				this.loading = false;
				this.cdr.detectChanges();
			}
		});
	}

	get visibleColumns() {
		return this.columns.filter((column) => column.visible).map((column) => column.property);
	}
	toggleColumnVisibility(column: TableColumn, event: Event) {
		event.stopPropagation();
		event.stopImmediatePropagation();
		column.visible = !column.visible;
	}
}
