<div class="grid grid-cols-1 mb-4">
	<div class="card">
		<div class="card-body p-4">
			<div class="flex items-center justify-between">
				<span class="mb-2">
					Toplamda <b>{{ enrollmentDeserved }}</b> onaylanmış kaydınız mevcut, paketiniz
					<b>{{ 'ENUM.PARTNER_PACKAGE_TYPE.' + partnerPackageType | translate }}</b>
				</span>
				<span class="text-xs text-blue-500 underline cursor-pointer" (click)="showPricingTable = !showPricingTable">
					Partner Tablosunu
					@if (showPricingTable) {
						Gizle
					} @else {
						Göster
					}
				</span>
			</div>
			@if (showPricingTable) {
				<div class="pricing-table">
					<table>
						<thead>
							<tr>
								<th></th>
								<th>Bireysel</th>
								<th>Gümüş</th>
								<th>Altın</th>
								<th>Platin</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>Dil Okulları</td>
								<td>{{ preferences.individual?.value?.languageSchool | premiumValue }}</td>
								<td>{{ preferences.silver?.value?.languageSchool | premiumValue }}</td>
								<td>{{ preferences.gold?.value?.languageSchool | premiumValue }}</td>
								<td>{{ preferences.platinum?.value?.languageSchool | premiumValue }}</td>
							</tr>
							<tr>
								<td>Bireysel Yaz/Kış Okulu Programları (Eğitim Bedeli)</td>
								<td>{{ preferences.individual?.value?.summerSchoolIndividual | premiumValue }}</td>
								<td>{{ preferences.silver?.value?.summerSchoolIndividual | premiumValue }}</td>
								<td>{{ preferences.gold?.value?.summerSchoolIndividual | premiumValue }}</td>
								<td>{{ preferences.platinum?.value?.summerSchoolIndividual | premiumValue }}</td>
							</tr>
							<tr>
								<td>Bireysel Yaz/Kış Okulu Programları (Paket Fiyat)</td>
								<td>{{ preferences.individual?.value?.summerSchoolIndividualPackage | premiumValue }}</td>
								<td>{{ preferences.silver?.value?.summerSchoolIndividualPackage | premiumValue }}</td>
								<td>{{ preferences.gold?.value?.summerSchoolIndividualPackage | premiumValue }}</td>
								<td>{{ preferences.platinum?.value?.summerSchoolIndividualPackage | premiumValue }}</td>
							</tr>
							<tr>
								<td>Grup Yaz/Kış Okulu Programları</td>
								<td>{{ preferences.individual?.value?.summerSchoolGroup | premiumValue }}</td>
								<td>{{ preferences.silver?.value?.summerSchoolGroup | premiumValue }}</td>
								<td>{{ preferences.gold?.value?.summerSchoolGroup | premiumValue }}</td>
								<td>{{ preferences.platinum?.value?.summerSchoolGroup | premiumValue }}</td>
							</tr>
							<tr>
								<td>Lisans, Lisansüstü, Lise, Foundation Programları, Sertifika ve Diploma Programları</td>
								<td>{{ preferences.individual?.value?.academicCommission | premiumValue }}</td>
								<td>{{ preferences.silver?.value?.academicCommission | premiumValue }}</td>
								<td>{{ preferences.gold?.value?.academicCommission | premiumValue }}</td>
								<td>{{ preferences.platinum?.value?.academicCommission | premiumValue }}</td>
							</tr>
							<tr>
								<td>Lisans, Lisansüstü,Lise, Sertifika ve Diploma Programları</td>
								<td>{{ preferences.individual?.value?.academicProgramFee | premiumValue }}</td>
								<td>{{ preferences.silver?.value?.academicProgramFee | premiumValue }}</td>
								<td>{{ preferences.gold?.value?.academicProgramFee | premiumValue }}</td>
								<td>{{ preferences.platinum?.value?.academicProgramFee | premiumValue }}</td>
							</tr>
							<tr>
								<td>Work and Travel Summer USA Programı</td>
								<td>{{ preferences.individual?.value?.workAndTravel | premiumValue }}</td>
								<td>{{ preferences.silver?.value?.workAndTravel | premiumValue }}</td>
								<td>{{ preferences.gold?.value?.workAndTravel | premiumValue }}</td>
								<td>{{ preferences.platinum?.value?.workAndTravel | premiumValue }}</td>
							</tr>
							<tr>
								<td>Staj/Kariyer Geliştirme Programları FULL Placement - Amerika</td>
								<td>{{ preferences.individual?.value?.internshipFullPlacementUsa | premiumValue }}</td>
								<td>{{ preferences.silver?.value?.internshipFullPlacementUsa | premiumValue }}</td>
								<td>{{ preferences.gold?.value?.internshipFullPlacementUsa | premiumValue }}</td>
								<td>{{ preferences.platinum?.value?.internshipFullPlacementUsa | premiumValue }}</td>
							</tr>
							<tr>
								<td>Staj/Kariyer Geliştirme Programları SELF Placement - Amerika</td>
								<td>{{ preferences.individual?.value?.internshipSelfPlacementUsa | premiumValue }}</td>
								<td>{{ preferences.silver?.value?.internshipSelfPlacementUsa | premiumValue }}</td>
								<td>{{ preferences.gold?.value?.internshipSelfPlacementUsa | premiumValue }}</td>
								<td>{{ preferences.platinum?.value?.internshipSelfPlacementUsa | premiumValue }}</td>
							</tr>
							<tr>
								<td>Staj/Kariyer Geliştirme Programları FULL Placement</td>
								<td>{{ preferences.individual?.value?.internshipFullPlacement | premiumValue }}</td>
								<td>{{ preferences.silver?.value?.internshipFullPlacement | premiumValue }}</td>
								<td>{{ preferences.gold?.value?.internshipFullPlacement | premiumValue }}</td>
								<td>{{ preferences.platinum?.value?.internshipFullPlacement | premiumValue }}</td>
							</tr>
							<tr>
								<td>Staj/Kariyer Geliştirme Programları SELF Placement</td>
								<td>{{ preferences.individual?.value?.internshipSelfPlacement | premiumValue }}</td>
								<td>{{ preferences.silver?.value?.internshipSelfPlacement | premiumValue }}</td>
								<td>{{ preferences.gold?.value?.internshipSelfPlacement | premiumValue }}</td>
								<td>{{ preferences.platinum?.value?.internshipSelfPlacement | premiumValue }}</td>
							</tr>
						</tbody>
					</table>
				</div>
			}
			<div class="mt-4 progress">
				<div class="flex justify-between">
					<div class="text-sm text-gray-600">
						{{ 'ENUM.PARTNER_PACKAGE_TYPE.4' | translate }}
					</div>
					<div class="text-sm text-gray-600">
						{{ 'ENUM.PARTNER_PACKAGE_TYPE.1' | translate }}
					</div>
					<div class="text-sm text-gray-600">
						{{ 'ENUM.PARTNER_PACKAGE_TYPE.2' | translate }}
					</div>
					<div class="text-sm text-gray-600">
						{{ 'ENUM.PARTNER_PACKAGE_TYPE.3' | translate }}
					</div>
				</div>
				<mat-progress-bar mode="determinate" [value]="deservedPercentage"></mat-progress-bar>
			</div>
		</div>
	</div>
</div>
