import { Component, Input } from '@angular/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { TranslateModule } from '@ngx-translate/core';
import { Preferences } from '../dashboard.component';
import { DashboardPremiumTablePremiumValuePipe } from './dashboard-premium-table-premium-value.pipe';

@Component({
	selector: 'dashboard-premium-table',
	standalone: true,
	imports: [MatProgressBarModule, TranslateModule, DashboardPremiumTablePremiumValuePipe],
	templateUrl: './dashboard-premium-table.component.html',
	styleUrl: './dashboard-premium-table.component.scss'
})
export class DashboardPremiumTableComponent {
	@Input() preferences!: Preferences;
	@Input() enrollmentDeserved!: number;
	@Input() partnerPackageType!: number;
	@Input() deservedPercentage!: number;

	showPricingTable = false;
}
