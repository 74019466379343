import { Pipe, PipeTransform } from '@angular/core';
import { PremiumPreferenceProgramTypeValue } from 'src/app/core/models/entities/premium-preference';

@Pipe({
	name: 'premiumValue',
	standalone: true
})
export class DashboardPremiumTablePremiumValuePipe implements PipeTransform {
	transform(value?: PremiumPreferenceProgramTypeValue): any {
		if (!value) {
			return '';
		}
		switch (value.premiumType) {
			case 1:
				return `${value.amount}%`;
			case 2:
				return `$${value.amount}`;
			case 3:
				return `$${value.schoolType1Amount} | $${value.schoolType2Amount} | $${value.schoolType3Amount}`;
		}
	}
}
