import { AsyncPipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import { PipesModule } from 'src/app/core/pipes/pipes.module';

@Component({
	selector: 'dashboard-partner-title',
	imports: [PipesModule, AsyncPipe],
	template: `
		<h1 class="text-xl">
			@switch (partnerType) {
				@case (1) {
					{{ partnerId | entity: 'StudyAbroadRepresentative' | async }}
				}
				@case (2) {
					{{ partnerId | entity: 'StudyAbroadPoint' | async }}
				}
				@case (3) {
					{{ partnerId | entity: 'School' | async }}
				}
				@case (4) {
					{{ partnerId | entity: 'CorporateInquiry' | async }}
				}
				@case (5) {
					{{ partnerId | entity: 'WebPortal' | async }}
				}
			}
		</h1>
	`,
	standalone: true
})
export class DashboardPartnerTitleComponent {
	@Input() partnerType!: number;
	@Input() partnerId!: number;
}
