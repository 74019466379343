import { Injectable } from '@angular/core';
import { firstValueFrom, Observable } from 'rxjs';
import { LookupService } from './lookup.service';

export interface EntityCacheInformation {
	entityName: string;
	value: string;
	observable: Observable<any[]>;
	cacheTime?: number;
}

export interface EntityCacheInformationLabel extends EntityCacheInformation {
	label?: string;
}

export interface EntityCacheInformationLabelFunc extends EntityCacheInformation {
	labelFunc?: (entity: any) => string;
}

export interface EntityInformation {
	entityName: string;
	cacheTime?: number;
	data: any[];
	entityLabels: EntityLabel[];
}

export interface EntityLabel {
	id: number;
	data: any;
	label: string;
}

@Injectable({
	providedIn: 'root'
})
export class EntityService {
	studyAbroadPointInformation: EntityCacheInformationLabel = {
		entityName: 'StudyAbroadPoint',
		observable: this.lookupService.studyAbroadPoints$,
		value: 'id',
		label: 'name'
	};
	studyAbroadRepresentativeInformation: EntityCacheInformationLabel = {
		entityName: 'StudyAbroadRepresentative',
		observable: this.lookupService.studyAbroadRepresentatives$,
		value: 'id',
		label: 'name'
	};
	corpareteInquiryInformation: EntityCacheInformationLabel = {
		entityName: 'CorporateInquiry',
		observable: this.lookupService.corporateInquiries$,
		value: 'id',
		label: 'companyName'
	};
	webPortalInformation: EntityCacheInformationLabel = {
		entityName: 'WebPortal',
		observable: this.lookupService.webPortals$,
		value: 'id',
		label: 'name'
	};
	serviceProviderInformation: EntityCacheInformationLabel = {
		entityName: 'ServiceProvider',
		observable: this.lookupService.serviceProviders$,
		value: 'id',
		label: 'name'
	};
	branchInformation: EntityCacheInformationLabel = {
		entityName: 'Branch',
		observable: this.lookupService.branches$,
		value: 'id',
		label: 'name'
	};
	userEntityInformation: EntityCacheInformationLabelFunc = {
		entityName: 'User',
		observable: this.lookupService.allUsers$,
		value: 'id',
		labelFunc: (entity: any) => `${entity?.firstName} ${entity?.lastName}`
	};
	schoolEntityInformation: EntityCacheInformationLabel = {
		entityName: 'School',
		observable: this.lookupService.schools$,
		value: 'id',
		label: 'name'
	};
	countryEntityInformation: EntityCacheInformationLabel = {
		entityName: 'Country',
		observable: this.lookupService.countries$,
		value: 'id',
		label: 'name'
	};
	cityEntityInformation: EntityCacheInformationLabel = {
		entityName: 'City',
		observable: this.lookupService.cities$,
		value: 'id',
		label: 'name'
	};

	entityCacheInformations: Array<EntityCacheInformationLabel | EntityCacheInformationLabelFunc> = [
		this.studyAbroadPointInformation,
		this.studyAbroadRepresentativeInformation,
		this.corpareteInquiryInformation,
		this.webPortalInformation,
		this.serviceProviderInformation,
		this.branchInformation,
		this.userEntityInformation,
		this.schoolEntityInformation,
		this.countryEntityInformation,
		this.cityEntityInformation
	];

	constructor(private lookupService: LookupService) {}

	private getEntityInformation(entityName: string): EntityCacheInformationLabel | EntityCacheInformationLabelFunc {
		var entityInformation = this.entityCacheInformations.find((x) => x.entityName == entityName);
		if (!entityInformation) {
			throw new Error(`Entity ${entityName} not found`);
		}
		return entityInformation;
	}

	async getEntityLabel(entityName: string, id: number): Promise<string> {
		var entityInformation = this.getEntityInformation(entityName);

		var entity = await this.getEntity(entityName, id);

		if ((entityInformation as EntityCacheInformationLabelFunc).labelFunc) {
			var entityInformationLabelFunc = entityInformation as EntityCacheInformationLabelFunc;
			if (entityInformationLabelFunc.labelFunc) {
				return entityInformationLabelFunc.labelFunc(entity);
			}
		}
		if ((entityInformation as EntityCacheInformationLabel).label) {
			var entityInformationLabel = entityInformation as EntityCacheInformationLabel;
			if (entityInformationLabel.label) {
				return entity[entityInformationLabel.label];
			}
		}
		throw new Error(`Label not found for entity ${entityName}`);
	}

	getEntities(entityName: string): Promise<any[]> {
		var entityInformation = this.getEntityInformation(entityName);
		return firstValueFrom(entityInformation.observable);
	}

	async getEntity(entityName: string, id: number): Promise<any> {
		var entities = await this.getEntities(entityName);

		var entity = entities.find((x) => x[this.getEntityInformation(entityName).value] == id);
		return entity;
	}
}
