import {
	AfterViewInit,
	ChangeDetectorRef,
	Component,
	ElementRef,
	NgIterable,
	OnDestroy,
	OnInit,
	ViewChild
} from '@angular/core';
import { FormControl, ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import { FieldType, FormlyModule } from '@ngx-formly/core';
import { MatSelect, MatSelectModule } from '@angular/material/select';
import { Observable, ReplaySubject, Subject, Subscription, map, take, takeUntil } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { UtclubFormlyFieldConfig } from './utclub-formly-field.interface';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CommonModule, NgIf } from '@angular/common';
import { PipesModule } from '../../pipes/pipes.module';

@Component({
	selector: 'formly-enum-select',
	standalone: true,
	imports: [
		FormlyModule,
		ReactiveFormsModule,
		MatSelectModule,
		MatInputModule,
		MatFormFieldModule,
		MatProgressSpinnerModule,

		NgIf,
		CommonModule,
		PipesModule
	],
	template: `
		<ng-container>
			<mat-form-field appearance="outline" style="width: 100%;">
				<mat-label> {{ field.props?.label }} </mat-label>

				<mat-select
					#selectInput
					[formControl]="control"
					[formlyAttributes]="field"
					[multiple]="field.props?.multiple"
					(opened)="onOpened()"
				>
					<ng-container *ngIf="filteredItems | async as optionList; else loading">
						<mat-option *ngFor="let item of filteredItems | async" [value]="item.value">
							{{ item.label }}
						</mat-option>
					</ng-container>
				</mat-select>

				<ng-template #loading>
					<mat-spinner diameter="20"></mat-spinner>
				</ng-template>
			</mat-form-field>
		</ng-container>
	`
})
export class EnumSelectFieldComponent
	extends FieldType<UtclubFormlyFieldConfig>
	implements OnInit, OnDestroy, AfterViewInit
{
	@ViewChild('input') input!: MatSelect;

	searchCtrl = new FormControl();

	items?: { value: any; label: string }[];
	filteredItems: ReplaySubject<{ value: any; label: string }[]> = new ReplaySubject<{ value: any; label: string }[]>();
	_onDestroy = new Subject<void>();
	constructor(private translateService: TranslateService) {
		super();
	}

	ngOnDestroy(): void {
		this._onDestroy.next();
		this._onDestroy.complete();
	}

	ngAfterViewInit(): void {
		this.setInitialValue();
	}

	setInitialValue() {
		this.filteredItems.pipe(take(1), takeUntil(this._onDestroy)).subscribe(() => {
			this.input.compareWith = (o1: any, o2: any) => {
				return o1 && o2 && o1 === o2;
			};
		});
	}

	filterItems() {
		if (!this.items) {
			return;
		}
		// get the search keyword
		let search = this.searchCtrl.value;
		if (!search) {
			this.filteredItems.next(this.items.slice());
			return;
		} else {
			search = search.toLowerCase();
		}
		// filter the banks
		this.filteredItems.next(this.items.filter((x) => x.label.toLowerCase().indexOf(search) > -1));
	}

	async ngOnInit() {
		if (!this.field.props) {
			throw new Error('props is required');
		}

		if (!this.field.props.valueProp) {
			this.field.props.valueProp = 'id';
		}
		if (!this.field.props.labelProp) {
			this.field.props.labelProp = 'name';
		}

		if (this.field.props.enum) {
			this.setEnum();
		} else {
			throw new Error('enum is required');
		}
	}

	setEnum() {
		var items = [];
		if (!this.field.props) {
			throw new Error('props is required');
		}
		if (!this.field.props.enum) {
			throw new Error('props.enum is required');
		}
		for (let key in this.field.props.enum.values) {
			items.push({
				value: this.field.props.enum.values[key],
				label: this.translateService.instant(`ENUM.${this.field.props.enum.key}.${this.field.props.enum.values[key]}`)
			});
		}
		this.items = items;
		this.filteredItems.next(this.items.slice());
	}

	onOpened() {
		this.searchCtrl.setValue('');
	}

	onClosed() {
		this.searchCtrl.setValue('');
	}

	get control() {
		return this.formControl as FormControl;
	}
}
